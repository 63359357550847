<template>
  <div class="Filters">
    <input
      v-if="!hideSearch"
      v-model="searchText"
      class="FilterInput HideOnMobileByDefault"
      :style="showSearchOnMobile ? 'display: block;' : ''"
      type="text"
      :placeholder="searchPlaceholder"
      @input="onSearchUpdated"
    />

    <div
      class="Filter ShowHideInputM"
      v-if="!showSearchOnMobile && !hideSearch"
      @click="showSearchOnMobile = true"
    >
      <div class="SInputIMG"></div>
    </div>

    <div
      class="Filter ShowHideInputM"
      v-if="showSearchOnMobile"
      @click="showSearchOnMobile = false"
    >
      <div class="FilterIMG"></div>
    </div>

    <div
      v-for="(f, findx) in filteredButtons"
      :key="`filter_${findx}`"
      @click.self="
        () => {
          if (openedMenu === f.name) {
            openedMenu = null;
          } else {
            openedMenu = f.name;
          }
        }
      "
      :class="
        `Filter noselect HideOnMobileByDefault ${
          openedMenu === f.name ? 'MFActive' : ''
        } ${f.selected.length != 0 ? 'NotEmpty' : ''}`
      "
      :style="
        `${openedMenu === f.name ? 'z-index: 5;' : ''} ${
          !showSearchOnMobile ? 'display: flex;' : ''
        }`
      "
    >
      <span
        @click.self="
          () => {
            if (openedMenu === f.name) {
              openedMenu = null;
            } else {
              openedMenu = f.name;
            }
          }
        "
        >{{ f.name }}</span
      >
      <div
        @click="
          () => {
            if (openedMenu === f.name) {
              openedMenu = null;
            } else {
              if (f.selected.length !== 0) {
                f.selected = [];
              } else {
                openedMenu = f.name;
              }
            }
          }
        "
        :class="
          f.selected.length === 0 || openedMenu === f.name
            ? 'FilterIMG'
            : 'RemoveFiltersIcon'
        "
      ></div>

      <div v-if="openedMenu === f.name" class="DropdownMenu">
        <div
          class="DDMElem"
          @click="
            () => {
              f.selected = [];
              filtersUpdated = true;
            }
          "
        >
          <span>Очистить выбор</span>
        </div>
        <div
          v-for="(fe, feindx) in f.list"
          :key="`filter_item_${feindx}_${fe}`"
          :class="{
            DDMElem: true,
            Active: filterIncluded(f.code, fe) !== -1,
          }"
          @click="
            () => {
              addOrRemoveFilter(f.code, fe);
              filtersUpdated = true;
            }
          "
        >
          <span>{{ fe }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchButtons",
  props: {
    searchPlaceholder: {
      type: String,
      default: "Поиск",
    },
    buttons: {
      type: Object,
      default: {},
    },
    searchInput: {
      type: String,
      default: "",
    },
    hideSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchText: "",
      openedMenu: null,
      showSearchOnMobile: false,
    };
  },
  watch: {
    openedMenu(newValue, oldValue) {
      this.followUpdated(newValue, oldValue);
    },
  },
  async created() {
    document.addEventListener("click", this.hideFilterMenuIfOpened);
  },
  computed: {
    filteredButtons() {
      if (this.buttons == null) {
        return [];
      }

      return Object.fromEntries(
        Object.entries(this.buttons).filter(([key, x]) => {
          if (x?.follow == null) {
            return true;
          }

          let find = this.buttons[x.follow];
          if (find == null) {
            return false;
          }

          if (find.selected.length != 0) {
            return true;
          }

          return false;
        })
      );
    },
  },
  methods: {
    filterIncluded(filterName, val) {
      return this.buttons[filterName].selected.indexOf(val);
    },

    addOrRemoveFilter(filterName, val) {
      let index = this.filterIncluded(filterName, val);
      if (index === -1) {
        this.buttons[filterName].selected.push(val);
      } else {
        this.buttons[filterName].selected.splice(index, 1);
      }
    },

    hideFilterMenuIfOpened(event) {
      if (this.openedMenu == null) {
        return 0;
      }

      let currentElement = event.target;

      let foundFilter = false;

      // Обходим родителей до #app
      while (currentElement && currentElement.id !== "app") {
        if (!foundFilter && currentElement.classList.contains("Filter")) {
          foundFilter = true; // Нашли .Filter
        } else if (
          foundFilter &&
          currentElement.classList.contains("DDMElem")
        ) {
          break; // Порядок найден, можно остановиться
        }
        currentElement = currentElement.parentElement; // Поднимаемся вверх по DOM
      }

      if (!foundFilter) {
        this.openedMenu = null;
      }
    },

    onSearchUpdated(event) {
      this.$emit("update:searchInput", event.target.value);
    },

    followUpdated(newValue, oldValue) {
      if (oldValue != null) {
        this.$emit("update:filters");

        //Данный код отвечал за обновление по вотчеру, однако это работает с переменным успехом
        // let oldValKey = null;

        // //Находим ключ который обновился
        // for (let key in this.buttons) {
        //   if (this.buttons[key].name === oldValue) {
        //     oldValKey = key;
        //     break;
        //   }
        // }

        // //Ищем, есть ли ключ, который ищет обновления
        // for (let key in this.buttons) {
        //   if (key != oldValKey) {
        //     if (this.buttons[key]?.follow === oldValKey) {
        //       this.$emit("update:filters");
        //     }
        //   }
        // }
      }
    },
  },
};
</script>

<style scoped>
.Filters {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;

  z-index: 500 !important;
}

.Filter > * {
  margin: auto 0%;
}
.Filter {
  position: relative;
  display: flex;

  cursor: pointer;

  padding: 14px 24px;

  width: fit-content;
  max-width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  border-radius: var(--o-s-global-border-radius);
  background-color: #423b8b;
}
.FilterIMG {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../../assets/img/ReDesign/interface_icons/filter_list_icon_16px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
}
.Filter > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  color: #ffffffe5;
}
.FilterInput {
  position: relative;
  display: block;

  padding: 14px 20px;

  width: 100%;
  max-width: 328px;
  height: 52px;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #ffffffe5;

  outline: transparent;
  background-color: transparent;

  border-radius: var(--o-s-global-border-radius);
  border: 1px solid #8f63f03d;
}

.FilterInput::placeholder {
  color: #ffffff66;
}

.SInputIMG {
  position: relative;
  display: block;

  width: 18px;
  height: 18px;

  background: url("./../../../assets/img/ReDesign/interface_icons/search_input_icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px;
}
.ShowHideInputM {
  display: none;
  flex-basis: auto;
  height: auto;
  width: fit-content;
  padding: 17px;
}

.RemoveFiltersIcon {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../../assets/img/ReDesign/interface_icons/close_icon_24px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
}

.Filter.NotEmpty {
  background-color: rgba(123, 88, 213, 1);
}

.DropdownMenu {
  position: absolute;
  display: flex;

  padding: 16px;

  left: 0;
  top: 60px;

  width: 360px;
  height: fit-content;
  max-height: 50svh;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  overflow-y: auto;

  border-radius: var(--o-s-global-border-radius);
  background-color: #8f63f021;

  backdrop-filter: blur(20px);
}
.DDMElem {
  position: relative;
  display: flex;

  padding: 14px 20px;

  width: 100%;
  height: fit-content;

  transition: 0.25s;

  border-radius: var(--o-s-global-border-radius);
  background-color: #423b8b;
}
.DDMElem.Active {
  background-color: #8f63f0;
}

.DDMElem > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  color: #ffffffe5;
}

@media (max-width: 992px) {
  .ShowHideInputM {
    display: flex;
  }

  .HideOnMobileByDefault {
    display: none;
  }

  .DropdownMenu {
    width: 100%;
  }
  .Filter.MFActive:not(.ShowHideInputM) {
    width: 100%;
  }
}
</style>
